<template>
  <div class="admin-wrap">
    <div class="admin-bar">
      <div class="left">
          <div class="nav-btn" @click="goto('/admin')"><i class="icon el-icon-back"></i> 返回</div>
          <div class="nav-title">首页 &lt; 纪念品兑换核销</div>
       </div>
       <div class="nav-btn" @click="logout()"><i class="icon el-icon-close"></i> 退出</div>
    </div>
    <div class="admin-body">
      <el-card class="box-card" style="width:100%">
        <div class="list-wrap">
          <div class="search">
            <el-form :inline="true" :model="search" class="demo-form-inline" onsubmit="return false;">
              <el-form-item label="">
                <el-input v-model="search.keywords" 
                  @keyup.enter.native="onSubmit"
                  id="qr-input"
                  size="small" placeholder="请输入关键字" clearable></el-input>
              </el-form-item>
<!--              <el-form-item label="核销时间">-->
<!--                <el-date-picker type="date" placeholder="请选择日期" v-model="search.date" style="width: 100%;" value-format="yyyy-MM-dd" clearable></el-date-picker>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="兑换项目">-->
<!--                <el-select v-model="search.jid" placeholder="请选择项目" clearable>-->
<!--                  <el-option label="不限" value=""></el-option>-->
<!--                  <el-option :label="item.title" v-for="item in items" :value="item.id" :key="item.id"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item>
                <el-button type="primary" @click="onSubmit" size="small">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="list">
            <el-table
              :data="list"
              stripe
              style="width: 100%">
              <el-table-column
                prop="no"
                label="兑换凭证号"
                >
              </el-table-column>
              <!-- <el-table-column
                prop="transfer_time"
                label="核销时间"
                width="180">
              </el-table-column> -->
              <el-table-column
                prop="jname"
                label="兑换项目">
              </el-table-column>
              <el-table-column
                  prop="orderno"
                  label="验证码">
              </el-table-column>
              <el-table-column
                prop="phone"
                label="兑换人手机号">
              </el-table-column>
              <el-table-column
                prop="idnumber"
                label="证件号">
              </el-table-column>
              <el-table-column
                align="center"
                label="操作">
                <template slot-scope="scope">
                  <el-button type="danger" @click="verify(scope.row)" size="mini">核销</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination
              layout=" prev, pager, next "
              @current-change="handleCurrentChange"
              @prev-click="handleCurrentChange"
              @next-click="handleCurrentChange"

              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import ScanReader from '@/libs/scanner'

export default {
  data() {
    return {
      search: {
        keywords: '',
        acid: 935,
        date: '',
        jid: '',
        page: 1
      },
      list: [],
      totalPage: 10,
      total: 0,
      items: []
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["getUser"]),
    logout() {
      this.setUser(null)
      this.$router.push({path: '/login?logout=1'})
    },
    goto(path) {
      this.$router.push({
        path
      })
    },
    load() {
      api.checkList(935, 0, this.search).then(r => {
        this.list = r.data.data.list
        this.total = r.data.data.page.total*1
        this.totalPage = r.data.data.page.pages*1
      })
      api.getItems(935).then(r => {
        this.items = r.data.data
      })
    },
    onSubmit() {
      this.search.page = 1
      this.load()
    },
    handleCurrentChange(page) {
      this.search.page = page
      this.load()
    },
    verify(item) {
      this.$confirm(`确认核销吗？`, '提示', {
        confirmButtonText: '核销',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        api.callNumber(935, item.id, 9).then(r => {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.load()
        })
      }).catch(() => {
      })
    }
  },
  mounted() {
    this.load()
    let that = this
    let reader = new ScanReader(function(v, o) {
          that.search.keywords = v
          that.load()
      }, document.getElementById('qr-input'))
    reader.init()
    this.scan = reader
  },
}
</script>

<style scoped>

.admin-bar {
  background: #C28A52;
  box-shadow: 0 5px 5px #684624;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  color: #fff;
}

.admin-bar .left {
  display: flex;
  justify-content: left;
}

.nav-title {
  margin: 10px 0 0 30px;
  height: 30px;
  line-height: 30px;
}

.admin-bar .nav-btn {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #fff;
  padding: 0 15px 0 10px;
  font-size: 14px;
}

.admin-body {
  display: flex;
  flex-direction:row;
  justify-content: stretch;
  padding: 2rem;
}

.admin-body .search {
  background: #fff;
  text-align: left;
}

.footer {
  margin-top: 1rem;
}
</style>
