// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".admin-bar[data-v-a75cfb6c]{background:#c28a52;box-shadow:0 .26vw .26vw #684624;height:3rem;display:flex;justify-content:flex-end;padding:0 2rem;color:#fff}.admin-bar .nav-btn[data-v-a75cfb6c]{margin-top:.521vw;height:1.458vw;line-height:1.458vw;border:1px solid #fff;padding:0 .781vw 0 .521vw;font-size:.729vw}.admin-body[data-v-a75cfb6c]{width:100%;margin:3rem auto;flex-direction:column}.admin-body[data-v-a75cfb6c],.admin-body .tit[data-v-a75cfb6c]{display:flex;justify-content:center}.admin-body .tit[data-v-a75cfb6c]{flex-grow:1 1 0;width:50%;min-width:13.021vw;max-width:29.167vw;margin:0 auto}.admin-body .tit img[data-v-a75cfb6c]{width:100%;height:100%}.admin-body .items[data-v-a75cfb6c]{display:flex;flex-wrap:wrap;flex-direction:row;justify-content:center;margin:3rem 0}.admin-body .item[data-v-a75cfb6c]{width:16rem;height:16rem;background:#c28a52 center 85% url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;box-shadow:1rem 1rem #95682f;margin:1rem;cursor:pointer}.admin-body .item .icon[data-v-a75cfb6c]{margin-top:2rem}.admin-body .item .text[data-v-a75cfb6c]{color:#fff;size:.938vw}", ""]);
// Exports
module.exports = exports;
