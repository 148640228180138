<template>
  <div class="admin-wrap">
    <div class="admin-bar">
       <div class="nav-btn" @click="logout()"><i class="icon el-icon-close"></i> 退出</div>
    </div>
    <div class="admin-body">
      <div class="tit"><img src="@/assets/tit.png" alt=""></div>
      <div class="items">
        <div class="item" @click="goto('/admin/queue')">
          <div class="icon">
            <img src="@/assets/1.png"/>
          </div>
          <div class="text">排队叫号核销</div>
        </div>
        <div class="item" @click="goto('/admin/verify')">
          <div class="icon">
            <img src="@/assets/2.png"/>
          </div>
          <div class="text">纪念品兑换核销</div>
        </div>
        <div class="item" @click="goto('/admin/record')">
          <div class="icon">
            <img src="@/assets/3.png"/>
          </div>
          <div class="text">查看核销记录</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(["role"])
  },
  methods: {
    ...mapMutations(["setRole", "setUser"]),
    ...mapGetters(["getRole"]),
    logout() {
      this.setUser(null)
      this.$router.push({path: '/login?admin=1&logout=1'})
    },
    goto(path) {
      this.$router.push({
        path
      })
    }
  },
  mounted() {
    api.userInfo().then(r => {
      if (r.data.status == 200) {
        this.setRole(r.data.data.role)
        if (this.getRole() != 2) {
          this.$router.push({name: 'Main'})
        }
      } else {
        this.$router.push({path: '/login?admin=1&logout=1'})
      }
    })
  },
}
</script>

<style scoped>
.admin-bar {
  background: #C28A52;
  box-shadow: 0 5px 5px #684624;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
  color: #fff;
}

.admin-bar .nav-btn {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #fff;
  padding: 0 15px 0 10px;
  font-size: 14px;
}

.admin-body {
  width: 100%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content : center;
}

.admin-body .tit {
  flex-grow: 1 1 0;
  display: flex;
  width: 50%;
  min-width: 250px;
  max-width: 560px;
  justify-content : center;
  margin: 0 auto;
}

.admin-body .tit img{
  width: 100%;
  height: 100%;
}

.admin-body .items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 3rem 0;
}

.admin-body .item {
  width: 16rem;
  height: 16rem;
  background: #C28A52 center 85% url("../../assets/arrow.png") no-repeat ;
  box-shadow: 1rem 1rem #95682F;
  margin: 1rem;
  cursor: pointer;
}
.admin-body .item .icon {
  margin-top: 2rem;
}
.admin-body .item .text {
  color: #fff;
  size: 18px;
}
</style>