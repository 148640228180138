<template>
  <div class="admin-wrap">
    <div class="admin-bar">
       <div class="left">
          <div class="nav-btn" @click="goto('/admin')"><i class="icon el-icon-back"></i> 返回</div>
          <div class="nav-title">首页 &lt; 排队叫号</div>
       </div>
       <div class="nav-btn" @click="logout()"><i class="icon el-icon-close"></i> 退出</div>
    </div>
    <div class="admin-body">
      <el-card class="box-card queue-card">
        <div class="queue-wrap">
          <span class="head">叫号区</span>
          <div class="queue-list">
            <el-table
              :data="queueList"
              header-row-class-name="queue-list-header"
              stripe
              style="width: 100%">
              <el-table-column
                prop="jname"
                label="项目">
              </el-table-column>
              <el-table-column
                prop="no"
                label="号码"
                >
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150"
                >
                <template slot-scope="scope">
                  <span>
                    <el-button type="primary" size="mini" @click="callNext(scope.row)">叫号</el-button>
                    <el-button type="warning" size="mini" @click="passNext(scope.row)">过号</el-button>
                  </span>
                  <!-- <span v-else><el-button size="mini" disabled>暂无叫号</el-button></span> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
      <el-card class="box-card list2-wrap">
        <span class="head">过号区</span>
        <div class="queue-list list2">
          <el-table
              :data="queueList2.filter(data => !filter || data.no.toLowerCase().includes(filter.toLowerCase()))"
              header-row-class-name="queue-list-header"
              height="260"
              stripe
              style="width: 100%">
              <el-table-column
                prop="no"
                align="center"
                label="号码"
                >
              </el-table-column>
              <el-table-column
                label=""
                align="center"
                width="120"
                >
                <template slot="header" slot-scope="scope">
                  <span v-if="queueList2.length && queueList2.length > 4">
                    <el-input
                      v-model="filter"
                      size="mini"
                      :ref="scope.$index"
                      placeholder="搜索"
                      clearable
                      />
                  </span>
                  <span v-else>
                    操作
                  </span>
                </template>
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" @click="recallNext(scope.row)">重新叫号</el-button>
                </template>
              </el-table-column>
            </el-table>
        </div>
      </el-card>
    </div>
    <div class="list-wrap">
      <el-card class="box-card queue-lists">
        <div class="head">项目排队情况</div>
        <div class="queue-lists-body">
          <div class="queue-list-content">
            <span class="item title">马路演绎</span>
            <div class="items">
              <span class="item" v-for="v in item220.data" :key="v.id">{{v.no}}</span>
            </div>
          </div>
          <div class="queue-list-content">
            <span class="item title">影动时空</span>
            <div class="items">
              <span class="item" v-for="v in item221.data" :key="v.id">{{v.no}}</span>
            </div>
          </div>
          <div class="queue-list-content">
            <span class="item title">雪地英雄</span>
            <div class="items">
              <span class="item" v-for="v in item227.data" :key="v.id">{{v.no}}</span>
            </div>

          </div>
          <div class="queue-list-content">
            <span class="item title">魔幻单车</span>
            <div class="items last">
              <span class="item" v-for="v in item228.data" :key="v.id">{{v.no}}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  data() {
    return {
      filter: '',
      search: {
        keywords: '',
        date: '',
        type: ''
      },
      queueList: [],
      queueList2: [],
      queueList3: []
    }
  },
  computed: {
    /*
    220	934	马路演绎\nCrazy Racing
221	934	影动时空\nSuper Hero
227	934	雪地英雄\nSkiing Simulator
228	934	魔幻单车\nMagical Bike
    */
    item220() {
      return this.queueList3.filter(v => v.jid == 220)[0] || {}
    },
    item221() {
      return this.queueList3.filter(v => v.jid == 221)[0] || {}
    },
    item227() {
      return this.queueList3.filter(v => v.jid == 227)[0] || {}
    },
    item228() {
      return this.queueList3.filter(v => v.jid == 228)[0] || {}
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["getUser"]),
    logout() {
      this.setUser(null)
      this.$router.push({path: '/login?logout=1'})
    },
    goto(path) {
      this.$router.push({
        path
      })
    },
    callNext(item) {
      let msgs = []
      msgs.push(`确认叫号 <span>${item.no}</span>`)
      this.$confirm(msgs.join('<br>'), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        api.callNumber(934, item.id, 9).then(r => {
          console.log(r)
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.load()
        })
      }).catch(() => {
      })
    },
    passNext(item) {
      this.$confirm(`确认过号吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        api.callNumber(934, item.id, 3).then(r => {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.load()
        })
      }).catch(() => {
      })
    },
    recallNext(item) {
      this.$confirm(`确认重新叫号吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        api.callNumber(934, item.id, 1).then(r => {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.load()
        })
      }).catch(() => {
      })
    },
    load() {
      api.callList(934, 0).then(r => {
        this.queueList = r.data.data || []
      })
      api.callList(934, 3).then(r => {
        this.queueList2 = r.data.data || []
      })
      api.waitingNumber(934, 0).then(r => {
        this.queueList3 = r.data.data ? (r.data.data[0] || [] ) : []
      })
    }
  },
  mounted() {
    this.load()
  },
}
</script>

<style scoped>

.admin-bar {
  background: #C28A52;
  box-shadow: 0 5px 5px #684624;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  color: #fff;
}

.admin-bar .left {
  display: flex;
  justify-content: left;
}

.nav-title {
  margin: 10px 0 0 30px;
  height: 30px;
  line-height: 30px;
}

.admin-bar .nav-btn {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #fff;
  padding: 0 15px 0 10px;
  font-size: 14px;
}

.admin-body {
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
}

.queue-card {
  flex: 3 1 60%;
  margin: 1rem;
}

.queue-list {
  margin-top: 1rem;
}

.head {
  font-family: 'DIN', 'Courier New', Courier, monospace;
}

.el-table th, .el-table tr.queue-list-header {
  background-color: #EDE7DD;
}

.list2-wrap {
  margin: 1rem;
  flex: 1 1 260px;
}

.list2 {
  background: rgb(163, 161, 161);
}

.list-wrap {
  margin: 1rem 2rem 1rem;
}

.list-wrap div.head {
  text-align: left;
}

.queue-lists-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.queue-list-content {
  flex: 1 1 200px;
}

.queue-list-content .title {
  color: #684624;
  display: block;
  padding-top: 1rem;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
  max-height: 200px;
  overflow-y: scroll;
  /* border-right: 1px solid #D6C9AC; */
  min-height: 150px;
  background: #e3dbc780;
}

.items .item {
  line-height: 3;
  color: #684624;
  flex: 0 1 33%;
  opacity: 1;
}

</style>